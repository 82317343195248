
import RcDomainMap from "../../common/RcDomainMap.js";
import Invoice from './Invoice.js';

export default class InvoiceMap extends RcDomainMap {
  static MODEL_NAME = Invoice.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data);
  }

  copy() {
    var data = this._copy();
    return new InvoiceMap(this.domain, data);
  }
}