<template>

  <div v-if="hasPdfUrl()">
    <a  :href="pdfUrl()" 
        target="_blank" 
        class="rc-input-button-nopadding rc-input-width">PDF</a>
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import StringUtils from '@/utils/StringUtils.js'
import Invoice from '@/domain/model/invoice/Invoice.js'

export default {
  name: 'invoice-pdf-link',
  components: {
  },
  props: {
    invoiceData: Object
  },
  data() {
    return {
    }
  },
  watch: {
    //watch
  },
  computed: {
    ...mapGetters([
      'domain',
    ]),
  },
  methods: {
    pdfUrl: function() {
      var invoice = new Invoice(this.domain, this.invoiceData);
      var url = invoice.pdfUrl();
      return url;
    },
    hasPdfUrl: function() {
      var invoice = new Invoice(this.domain, this.invoiceData);
      if (StringUtils.isOk(invoice.pdfUrl())) {
        return true;
      }
      return false;
    },
  }
}
</script>